<template>
  <div>
    <div class="questionAndAnswerHeader" :class="sidebarExpand?'sidebarExpand':''">
      <div class="headerBox">
        <a href="javascript:;" @click="sidebarExpand=true"><svg-icon icon-class="icon-expand" /></a>
        <img src="@/assets/image/home/logo.png" alt="zxlogo" @click="$router.push({name:'home'})">
        <h2>帮助中心</h2>
      </div>
      <div class="sidebarBox" v-if="sidebarExpand">
        <div>
          <a href="javascript:;" @click="sidebarExpand=false"><svg-icon icon-class="icon-close" /></a>
          <div class="sidebars">
            <h4 v-for="(item,index) in types" :key="index" :class="item.id===sidebarType?'active':''" @click="typeCg(item.id)">{{item.categoryName}}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="questionAndAnswerBox">
      <h2>{{categoryName}}</h2>
      <div class="questionAndAnswerList" v-for="(item,index) in texts" :key="index">
        <div class="question" @click="getHelpcontentInfo (item.id)">
          <span>{{item.contentTitle}}</span><i class="el-icon-arrow-down"></i>
        </div>
        <el-collapse-transition>
          <div v-if="item.id==showAnswer">
            <div class="answer" v-html="answer.contentText" :class="{long:answer.contentText&&answer.contentText.length>460&&answer.id!==showMore}"></div>
            <template v-if="answer.contentText&&answer.contentText.length>460">
              <el-button v-if="item.id!==showMore" class="more" @click.stop="showMore=item.id" type="text">—>查看全部</el-button>
              <el-button v-else class="more" @click.stop="showMore=-1" type="text">—>收起</el-button>
            </template>
          </div>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type: 5,
      types: [],
      showAnswer: -1,
      showMore: -1,
      answer: {},
      texts: [],
      pageIndex: 1,
      pageSize: 10,
      sidebarExpand: false,
      sidebarType: ''
    }
  },
  created () {
    this.getContentcategory()
  },
  mounted () {
    this.texts = []
  },
  methods: {
    getContentcategory () {
      this.$api.getContentcategory({ categoryType: 2 }).then(res => {
        this.types = res.data
        let type = this.$route.query.type
        this.type = type || type === 0 ? type : res.data[0].id
        this.getHelpcontentList(type)
      })
    },
    getHelpcontentList (type) {
      this.$api
        .getHelpcontentList({
          categoryId: this.type,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        })
        .then(res => {
          if (type === this.type) {
            this.texts.push(...res.data.list)
            if (res.data.hasNext) {
              this.pageIndex++
              this.getHelpcontentList(type)
            }
          }
        })
    },
    getHelpcontentInfo (id) {
      if (this.showAnswer !== id) {
        this.$api.getHelpcontentInfo({ id }).then(res => {
          this.answer = res.data || {}
          this.$nextTick(() => {
            this.showAnswer = id
          })
        })
      }
      this.showAnswer = -1
    },
    typeCg (type) {
      if (type !== this.type) {
        this.showAnswer = -1
        this.type = type
        this.pageIndex = 1
        this.$router.push({
          query: { type }
        })
        this.texts = []

        this.getHelpcontentList(type)
        this.sidebarExpand = false
      }
    }
  },
  computed: {
    categoryName () {
      let name = ''
      for (let type of this.types) {
        if (type.id === this.type) {
          name = type.categoryName
        }
      }
      return name
    }
  },
  watch: {
    $route: {
      handler (to) {
        this.sidebarType = to.query.type
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.questionAndAnswerHeader {
  width: 7.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(31, 37, 81, 0.11);
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
}
.headerBox {
  height: 0.88rem;
  display: flex;
  align-items: center;
  padding: 0.32rem;
  box-sizing: border-box;
  a {
    font-size: 0.36rem;
    color: #656565;
    margin-right: 0.32rem;
  }
  img {
    width: 0.55rem;
    height: 0.4rem;
    margin-right: 0.32rem;
  }
  h2 {
    font-size: 0.28rem;
    font-weight: 400;
    color: #2d3b52;
    line-height: 0.4rem;
    padding-left: 0.35rem;
    position: relative;
    &::before {
      content: '';
      width: 0.03rem;
      height: 0.36rem;
      background: #656565;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.sidebarExpand {
  width: 7.5rem;
  right: 0;
  bottom: 0;
}
.sidebarBox {
  width: 7.5rem;
  height: 100%;
  background: rgba(0, 0, 0, 0.29);
  border-top: 1px solid #d8d8d8;
  position: absolute;
  top: 0.88rem;
  left: 0;
  > div {
    width: 3.74rem;
    height: 100%;
    padding: 0.3rem 0.16rem 0 0;
    box-sizing: border-box;
    background: #ffffff;
    text-align: right;
  }
  a {
    font-size: 0.24rem;
    color: #656565;
  }
}
.sidebars {
  padding-top: 0.46rem;
  h4 {
    font-size: 0.28rem;
    font-weight: 400;
    color: #303b50;
    line-height: 0.4rem;
    margin-bottom: 0.48rem;
    padding-left: 0.2rem;
    text-align: left;
  }
  .active {
    color: #0059ff;
    position: relative;
    &::after {
      content: '';
      width: 0.04rem;
      height: 0.3rem;
      background: #0059ff;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}
.questionAndAnswerBox {
  padding: 0.62rem 0.32rem 0.9rem;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin-bottom: 0.52rem;
  }
}
.questionAndAnswerList {
  background: #ffffff;
  border-radius: 0.04rem;
  border: 1px solid #d8d8d8;
  margin-bottom: 0.32rem;
  .el-button {
    margin-left: 0.24rem;
    color: #0900db;
  }
}
.question {
  padding: 0.24rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 0.28rem;
    font-weight: bold;
    color: #303b50;
    line-height: 0.4rem;
  }
}
.answer {
  border-top: 1px solid #d8d8d8;
  padding: 0.24rem;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 0.26rem;
  color: #0900db;
  line-height: 0.46rem;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
  &.long {
    max-height: 256px;

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.96) 100%
      );
    }
  }
}
</style>